import { useState, useEffect } from 'react';
import { partnerLogos } from '../utils/assetMap';

function PBXIntegrationFilters() {
  const integrations = [
    { id: 'teams', name: 'Microsoft Teams', logo: partnerLogos.teams },
    { id: 'webex', name: 'Webex', logo: partnerLogos.webex },
    { id: 'cisco', name: 'Cisco', logo: partnerLogos.cisco },
    { id: 'avaya', name: 'Avaya', logo: partnerLogos.avaya },
    { id: 'nec', name: 'NEC', logo: partnerLogos.nec },
    { id: 'nice', name: 'Nice', logo: partnerLogos.nice }
  ];

  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulate loading state
    setIsLoading(false);
  }, []);

  if (isLoading) return <div>Loading integrations...</div>;
  if (error) return <div>Error loading integrations: {error}</div>;

  const togglePlatform = (platform) => {
    setSelectedPlatforms(prev =>
      prev.includes(platform) ? prev.filter(p => p !== platform) : [...prev, platform]
    );
  };

  const selectAll = () => {
    setSelectedPlatforms(integrations.map(integration => integration.name));
  };

  return (
    <section className="mb-6">
      <div className="bg-white dark:bg-gray-800 p-6 rounded shadow">
        <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">PBX Integration Filters</h2>
        <button 
          onClick={selectAll} 
          className="mb-4 p-2 bg-primary text-white rounded hover:bg-primary/90 transition-colors"
        >
          Select All
        </button>
        <div className="flex flex-wrap gap-4">
          {integrations.map(integration => (
            <button
              key={integration.id}
              onClick={() => togglePlatform(integration.name)}
              className={`flex items-center p-2 rounded transition-colors ${
                selectedPlatforms.includes(integration.name) 
                  ? 'bg-secondary text-white hover:bg-secondary/90' 
                  : 'bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-500'
              }`}
            >
              <img 
                src={integration.logo} 
                alt={`${integration.name} Logo`} 
                className="w-6 h-6 mr-2" 
              />
              <span>{integration.name}</span>
            </button>
          ))}
        </div>
      </div>
    </section>
  );
}

export default PBXIntegrationFilters;