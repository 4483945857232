import { useState } from 'react';
import { Trash2 } from 'lucide-react';

const sampleProducts = [
  {
    id: 1,
    name: 'Analytics Tool A',
    category: 'Analytics Tools',
    price: '$49.99',
    feature1: 'Advanced Data Visualization',
    feature2: 'Real-Time Analytics',
  },
  {
    id: 2,
    name: 'Communication Platform B',
    category: 'Communication Platforms',
    price: '$29.99',
    feature1: 'Seamless Team Communication',
    feature2: 'Multiple Integrations',
  },
  {
    id: 3,
    name: 'Productivity App C',
    category: 'Productivity Apps',
    price: '$19.99',
    feature1: 'Task Management',
    feature2: 'Scheduling',
  },
];

function ProductComparison({ products = [] }) {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const toggleProductSelection = (product) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(product)
        ? prevSelected.filter((p) => p !== product)
        : [...prevSelected, product]
    );
  };

  return (
    <section className="mb-6">
      <h2 className="text-xl font-semibold mb-4">Product Comparison</h2>
      <div className="flex flex-wrap gap-4">
        {products.map((product) => (
          <div
            key={product.id}
            className={`p-4 border rounded ${
              selectedProducts.includes(product) ? 'border-primary' : 'border-gray-300'
            }`}
          >
            <h3 className="font-semibold">{product.name}</h3>
            <button
              onClick={() => toggleProductSelection(product)}
              className="mt-2 px-2 py-1 bg-primary text-white rounded"
            >
              {selectedProducts.includes(product) ? 'Remove' : 'Compare'}
            </button>
          </div>
        ))}
      </div>

      {selectedProducts.length > 0 && (
        <div className="mt-6 overflow-auto">
          <table className="min-w-full text-left">
            <thead>
              <tr>
                <th className="px-4 py-2">Feature</th>
                {selectedProducts.map((product) => (
                  <th key={product.id} className="px-4 py-2">
                    {product.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {/* Assuming each product has a 'features' array */}
              {selectedProducts[0].features.map((feature, index) => (
                <tr key={index}>
                  <td className="border px-4 py-2">{feature}</td>
                  {selectedProducts.map((product) => (
                    <td key={product.id} className="border px-4 py-2">
                      {product.features[index] || '-'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
}

export default ProductComparison;