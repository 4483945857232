// src/components/reports.jsx

import React, { useState, useEffect } from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline'; // Updated import for Heroicons v2
import { SchedulerWorkflow } from './SchedulerWorkflow';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ReportsComponent = () => {
  const [isSchedulerOpen, setIsSchedulerOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [scheduleData, setScheduleData] = useState({
    reportId: '',
    frequency: '',
    startTime: '',
    notifications: [],
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [schedules, setSchedules] = useState([]);

  useEffect(() => {
    fetchSchedules();
  }, []);

  const fetchSchedules = async () => {
    setLoading(true);
    try {
      // Mock data for demo
      const mockSchedules = [
        {
          id: '1',
          reportId: 'report1',
          frequency: 'daily',
          startTime: '09:00',
          notifications: ['email']
        },
        {
          id: '2',
          reportId: 'report2',
          frequency: 'weekly',
          startTime: '10:00',
          notifications: ['email', 'sms']
        }
      ];
      
      setSchedules(mockSchedules);
      setError(null);
    } catch (err) {
      setError('Unable to load schedules');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleScheduleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Mock successful submission
      const newSchedule = {
        id: String(schedules.length + 1),
        ...scheduleData
      };
      
      setSchedules(prev => [...prev, newSchedule]);
      setIsSchedulerOpen(false);
      resetScheduler();
      toast.success('Schedule created successfully!');
    } catch (error) {
      setError('Failed to create schedule');
      console.error(error);
      toast.error('Failed to create schedule.');
    } finally {
      setLoading(false);
    }
  };

  const handleStepChange = (step) => {
    if (step > currentStep && !validateCurrentStep()) {
      toast.error('Please complete the current step before proceeding.');
      return;
    }
    setCurrentStep(step);
  };

  const validateCurrentStep = () => {
    switch (currentStep) {
      case 1:
        return scheduleData.reportId.trim() !== '';
      case 2:
        return scheduleData.frequency.trim() !== '';
      case 3:
        return scheduleData.startTime.trim() !== '';
      default:
        return true;
    }
  };

  const resetScheduler = () => {
    setCurrentStep(1);
    setScheduleData({
      reportId: '',
      frequency: '',
      startTime: '',
      notifications: [],
    });
    setError(null);
  };

  const handleOpenScheduler = () => {
    resetScheduler();
    setIsSchedulerOpen(true);
  };

  const handleCloseScheduler = () => {
    setIsSchedulerOpen(false);
    resetScheduler();
  };

  const handleEditSchedule = (schedule) => {
    setScheduleData({
      reportId: schedule.reportId,
      frequency: schedule.frequency,
      startTime: schedule.startTime,
      notifications: schedule.notifications,
    });
    setCurrentStep(1);
    setIsSchedulerOpen(true);
  };

  const handleDeleteSchedule = async (scheduleId) => {
    if (!window.confirm('Are you sure you want to delete this schedule?')) return;

    setLoading(true);
    try {
      // Mock successful deletion
      setSchedules(prev => prev.filter(schedule => schedule.id !== scheduleId));
      toast.success('Schedule deleted successfully!');
    } catch (error) {
      setError('Failed to delete schedule');
      console.error(error);
      toast.error('Failed to delete schedule.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ToastContainer />

      {loading && <p className="text-gray-600">Loading...</p>}
      {error && <p className="text-red-500 mb-4">{error}</p>}
      
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold">Report Scheduler</h1>
        <button
          onClick={handleOpenScheduler}
          className="flex items-center px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
          disabled={loading}
          aria-label="Open Schedule Report Dialog"
        >
          <CalendarIcon className="h-6 w-6 mr-2 text-blue-600" />
          <span>Schedule Report</span>
        </button>
      </div>

      {/* Existing Schedules */}
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Existing Schedules</h2>
        {schedules.length > 0 ? (
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Report ID</th>
                <th className="py-2 px-4 border-b">Frequency</th>
                <th className="py-2 px-4 border-b">Start Time</th>
                <th className="py-2 px-4 border-b">Notifications</th>
                <th className="py-2 px-4 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {schedules.map(schedule => (
                <tr key={schedule.id} className="text-center">
                  <td className="py-2 px-4 border-b">{schedule.reportId}</td>
                  <td className="py-2 px-4 border-b capitalize">{schedule.frequency}</td>
                  <td className="py-2 px-4 border-b">{schedule.startTime}</td>
                  <td className="py-2 px-4 border-b">
                    {schedule.notifications.length > 0
                      ? schedule.notifications.join(', ')
                      : 'None'}
                  </td>
                  <td className="py-2 px-4 border-b">
                    <button
                      onClick={() => handleEditSchedule(schedule)}
                      className="text-blue-600 hover:underline mr-2"
                      aria-label={`Edit schedule for report ${schedule.reportId}`}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteSchedule(schedule.id)}
                      className="text-red-600 hover:underline"
                      aria-label={`Delete schedule for report ${schedule.reportId}`}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No schedules found.</p>
        )}
      </div>

      {/* Scheduler Modal */}
      {isSchedulerOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          role="dialog"
          aria-labelledby="scheduler-modal-title"
        >
          <div className="bg-white rounded-lg p-6 w-full max-w-2xl relative overflow-y-auto max-h-screen">
            {/* Close Button */}
            <button
              onClick={handleCloseScheduler}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              aria-label="Close Scheduler Modal"
            >
              {/* Close Icon */}
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* Modal Title */}
            <h2 id="scheduler-modal-title" className="text-xl font-semibold mb-4">Schedule Report</h2>

            {/* Scheduler Workflow */}
            <SchedulerWorkflow currentStep={currentStep} />

            {/* Scheduler Form */}
            <form onSubmit={handleScheduleSubmit} className="mt-6">
              {/* Step 1: Select Report */}
              {currentStep === 1 && (
                <div className="space-y-4">
                  <label className="block">
                    <span className="text-gray-700">Select Report</span>
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
                      value={scheduleData.reportId}
                      onChange={(e) => setScheduleData({...scheduleData, reportId: e.target.value})}
                      required
                    >
                      <option value="">Choose a report</option>
                      {/* Replace with actual report options */}
                      <option value="report1">Sales Report</option>
                      <option value="report2">Inventory Report</option>
                      <option value="report3">Finance Report</option>
                    </select>
                  </label>
                </div>
              )}

              {/* Step 2: Configure Schedule */}
              {currentStep === 2 && (
                <div className="space-y-4">
                  <label className="block">
                    <span className="text-gray-700">Frequency</span>
                    <select
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
                      value={scheduleData.frequency}
                      onChange={(e) => setScheduleData({...scheduleData, frequency: e.target.value})}
                      required
                    >
                      <option value="">Select Frequency</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="monthly">Monthly</option>
                    </select>
                  </label>
                </div>
              )}

              {/* Step 3: Set Notifications */}
              {currentStep === 3 && (
                <div className="space-y-4">
                  <label className="block">
                    <span className="text-gray-700">Start Time</span>
                    <input
                      type="time"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200"
                      value={scheduleData.startTime}
                      onChange={(e) => setScheduleData({...scheduleData, startTime: e.target.value})}
                      required
                    />
                  </label>
                  <label className="block">
                    <span className="text-gray-700">Notifications</span>
                    <div className="flex items-center mt-2">
                      <label className="mr-4 flex items-center">
                        <input
                          type="checkbox"
                          checked={scheduleData.notifications.includes('email')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setScheduleData({
                                ...scheduleData,
                                notifications: [...scheduleData.notifications, 'email'],
                              });
                            } else {
                              setScheduleData({
                                ...scheduleData,
                                notifications: scheduleData.notifications.filter(
                                  (n) => n !== 'email'
                                ),
                              });
                            }
                          }}
                          className="mr-2"
                        />
                        Email
                      </label>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={scheduleData.notifications.includes('sms')}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setScheduleData({
                                ...scheduleData,
                                notifications: [...scheduleData.notifications, 'sms'],
                              });
                            } else {
                              setScheduleData({
                                ...scheduleData,
                                notifications: scheduleData.notifications.filter(
                                  (n) => n !== 'sms'
                                ),
                              });
                            }
                          }}
                          className="mr-2"
                        />
                        SMS
                      </label>
                    </div>
                  </label>
                </div>
              )}

              {/* Step 4: Review & Save */}
              {currentStep === 4 && (
                <div className="space-y-4">
                  <p className="text-gray-700">Review your schedule details before saving.</p>
                  <ul className="list-disc list-inside">
                    <li>Report ID: {scheduleData.reportId}</li>
                    <li>Frequency: {scheduleData.frequency}</li>
                    <li>Start Time: {scheduleData.startTime}</li>
                    <li>
                      Notifications:{' '}
                      {scheduleData.notifications.length > 0
                        ? scheduleData.notifications.join(', ')
                        : 'None'}
                    </li>
                  </ul>
                </div>
              )}

              {/* Navigation Buttons */}
              <div className="flex justify-between mt-6">
                <button
                  type="button"
                  onClick={() => {
                    if (currentStep === 1) {
                      handleCloseScheduler();
                    } else {
                      setCurrentStep((prev) => prev - 1);
                    }
                  }}
                  className="px-4 py-2 border rounded-md hover:bg-gray-100 transition-colors"
                >
                  {currentStep === 1 ? 'Cancel' : 'Previous'}
                </button>
                <div className="space-x-2">
                  {currentStep < 4 ? (
                    <button
                      type="button"
                      onClick={() => handleStepChange(currentStep + 1)}
                      className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
                    >
                      Next
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 transition-colors"
                      disabled={loading}
                    >
                      {loading ? 'Saving...' : 'Save Schedule'}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsComponent;