import { Info } from 'lucide-react';

function DeploymentStatus({ deployments }) {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'active': return 'bg-green-500';
      case 'deploying': return 'bg-yellow-500';
      case 'idle': return 'bg-gray-500';
      default: return 'bg-gray-500';
    }
  };

  const formatTime = (dateString) => {
    return new Date(dateString).toLocaleTimeString();
  };

  return (
    <section className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
        Automated Deployment Status
      </h2>
      <div className="space-y-4">
        {deployments.map(deployment => (
          <div key={deployment.id} className="border-b border-gray-200 dark:border-gray-700 pb-4 last:border-0">
            <div className="flex items-center justify-between mb-2">
              <h3 className="font-semibold text-gray-800 dark:text-gray-200">
                {deployment.name}
              </h3>
              <span className={`px-3 py-1 rounded-full text-xs font-medium text-white ${getStatusColor(deployment.status)}`}>
                {deployment.status}
              </span>
            </div>
            <div className="relative h-2 bg-gray-200 dark:bg-gray-700 rounded overflow-hidden">
              <div 
                className={`absolute left-0 top-0 h-full ${getStatusColor(deployment.status)}`}
                style={{ width: `${deployment.progress}%` }}
              />
            </div>
            <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              {deployment.status === 'Deploying' && (
                <p>Started at {formatTime(deployment.startTime)} • ETA: {formatTime(deployment.eta)}</p>
              )}
              {deployment.status === 'Active' && (
                <p>Completed at {formatTime(deployment.completedTime)}</p>
              )}
              {deployment.status === 'Idle' && (
                <p>Scheduled for {formatTime(deployment.scheduledTime)}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default DeploymentStatus;