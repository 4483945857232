import React from 'react';
import { Settings2, UserCog, Bell, Globe, Shield, Database } from 'lucide-react';

function Settings() {
  const settingsCards = [
    {
      icon: UserCog,
      title: "Account Settings",
      description: "Manage your account preferences, profile, and authentication options.",
      action: "Manage Account",
      path: "/settings/account"
    },
    {
      icon: Bell,
      title: "Notifications",
      description: "Configure your notification preferences and alert settings.",
      action: "Configure",
      path: "/settings/notifications"
    },
    {
      icon: Globe,
      title: "Language & Region",
      description: "Set your preferred language and regional settings.",
      action: "Change Settings",
      path: "/settings/language"
    },
    {
      icon: Shield,
      title: "Privacy & Security",
      description: "Review and update your privacy and security settings.",
      action: "Manage Security",
      path: "/settings/security"
    },
    {
      icon: Database,
      title: "Data Management",
      description: "Control your data preferences and export options.",
      action: "Manage Data",
      path: "/settings/data"
    },
    {
      icon: Settings2,
      title: "Advanced Settings",
      description: "Configure advanced features and system preferences.",
      action: "Configure",
      path: "/settings/advanced"
    }
  ];

  return (
    <main className="p-6" role="main">
      <h1 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-200">
        Settings
      </h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {settingsCards.map((card, index) => (
          <div 
            key={index}
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md
              hover:shadow-lg focus-within:ring-2 focus-within:ring-primary
              transform transition-all duration-200 hover:-translate-y-1"
            role="region"
            aria-labelledby={`settings-title-${index}`}
          >
            <card.icon 
              className="h-8 w-8 text-primary mb-4" 
              aria-hidden="true"
            />
            <h2 
              id={`settings-title-${index}`}
              className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200"
            >
              {card.title}
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              {card.description}
            </p>
            <button
              onClick={() => window.location.href = card.path}
              className="inline-flex items-center text-primary hover:text-primary-dark
                focus:outline-none focus:ring-2 focus:ring-primary rounded px-2 py-1
                font-medium transition-colors group"
              aria-label={`${card.action} ${card.title.toLowerCase()}`}
            >
              {card.action}
              <span 
                className="ml-1 transform transition-transform group-hover:translate-x-1"
                aria-hidden="true"
              >
                →
              </span>
            </button>
          </div>
        ))}
      </div>
    </main>
  );
}

export default Settings;
