import { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import TopNavigationBar from './components/TopNavigationBar';
import SidebarNavigation from './components/SidebarNavigation';
import Dashboard from './pages/Dashboard';
import Products from './pages/Products';
import Reports from './pages/Reports';
import DataSources from './pages/DataSources';
import QueryBuilder from './pages/QueryBuilder';
import Settings from './pages/Settings';
import Support from './pages/Support';  // Make sure this import is correct

function App() {
  const [isInitializing, setIsInitializing] = useState(true);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [theme, setTheme] = useState('light');

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme') || 'light';
    setTheme(storedTheme);
    document.documentElement.classList.add(storedTheme);
  }, []);

  useEffect(() => {
    // Simulate initialization process
    const timer = setTimeout(() => setIsInitializing(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    document.documentElement.classList.replace(theme, newTheme);
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  if (isInitializing) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-100 dark:bg-gray-900">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <div className={theme === 'dark' ? 'dark' : ''}>
      <div className="flex flex-col min-h-screen">
        <div className="flex h-screen bg-gray-100 dark:bg-gray-900">
          <SidebarNavigation 
            isOpen={sidebarOpen} 
            setIsOpen={setSidebarOpen}
          />
          
          <div className="flex flex-col flex-1 overflow-hidden">
            <TopNavigationBar 
              setSidebarOpen={setSidebarOpen} 
              toggleTheme={toggleTheme} 
              theme={theme} 
            />
            
            <div className="flex-1 overflow-auto">
              <div className="container mx-auto px-4">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/products" element={<Products />} />
                  <Route path="/reports" element={<Reports />} />
                  <Route path="/data-sources" element={<DataSources />} />
                  <Route path="/query-builder" element={<QueryBuilder />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/support" element={<Support />} />
                  {/* Catch all route for 404 */}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;