import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Play, Save, Copy, Trash2, Database, Table } from 'lucide-react';

function QueryBuilder() {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSource, setSelectedSource] = useState('');
  const [queryInput, setQueryInput] = useState('');
  const [queryResult, setQueryResult] = useState(null);
  const [savedQueries, setSavedQueries] = useState([
    { id: 1, name: 'Daily Active Users', query: 'SELECT COUNT(DISTINCT user_id) FROM user_sessions WHERE date = CURRENT_DATE' },
    { id: 2, name: 'Monthly Revenue', query: 'SELECT SUM(amount) FROM transactions WHERE MONTH(date) = MONTH(CURRENT_DATE)' }
  ]);

  const dataSources = [
    { id: 'analytics_db', name: 'Analytics Database' },
    { id: 'user_data', name: 'User Data Warehouse' },
    { id: 'metrics_db', name: 'Metrics Database' }
  ];

  useEffect(() => {
    setTimeout(() => setIsLoading(false), 800);
  }, []);

  const handleRunQuery = async () => {
    setIsLoading(true);
    try {
      // Simulate query execution
      await new Promise(resolve => setTimeout(resolve, 1000));
      setQueryResult({
        columns: ['id', 'name', 'value'],
        rows: [
          { id: 1, name: 'Result 1', value: 100 },
          { id: 2, name: 'Result 2', value: 200 }
        ]
      });
    } catch (error) {
      console.error('Query execution failed:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const saveQuery = () => {
    if (queryInput.trim()) {
      setSavedQueries(prev => [...prev, {
        id: Date.now(),
        name: `Query ${prev.length + 1}`,
        query: queryInput
      }]);
    }
  };

  return (
    <main className="p-4 space-y-4">
      {/* Breadcrumb Navigation */}
      <nav className="flex items-center text-sm text-gray-500 dark:text-gray-400">
        <Link to="/" className="hover:text-primary">Home</Link>
        <ChevronRight size={16} className="mx-2" />
        <span className="text-gray-900 dark:text-gray-200">Query Builder</span>
      </nav>

      {/* Header Section */}
      <header className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">Query Builder</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Build and execute custom queries across your data sources
        </p>
      </header>

      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Saved Queries Sidebar */}
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <h2 className="text-lg font-semibold mb-4 flex items-center">
            <Save size={20} className="mr-2" />
            Saved Queries
          </h2>
          <div className="space-y-2">
            {savedQueries.map(query => (
              <button
                key={query.id}
                onClick={() => setQueryInput(query.query)}
                className="w-full text-left p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
              >
                {query.name}
              </button>
            ))}
          </div>
        </div>

        {/* Main Query Building Area */}
        <div className="lg:col-span-3 space-y-4">
          {/* Data Source Selection */}
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <div className="flex items-center mb-4">
              <Database size={20} className="mr-2 text-primary" />
              <select
                value={selectedSource}
                onChange={(e) => setSelectedSource(e.target.value)}
                className="form-select w-full max-w-xs bg-gray-50 dark:bg-gray-700 border-gray-300 dark:border-gray-600 rounded"
              >
                <option value="">Select Data Source</option>
                {dataSources.map(source => (
                  <option key={source.id} value={source.id}>{source.name}</option>
                ))}
              </select>
            </div>
          </div>

          {/* Query Editor */}
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            <div className="mb-4">
              <textarea
                value={queryInput}
                onChange={(e) => setQueryInput(e.target.value)}
                placeholder="Enter your SQL query here..."
                className="w-full h-40 p-4 bg-gray-50 dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded font-mono"
              />
            </div>
            <div className="flex space-x-2">
              <button
                onClick={handleRunQuery}
                disabled={!selectedSource || !queryInput.trim()}
                className="flex items-center px-4 py-2 bg-primary text-white rounded hover:bg-primary/90 disabled:opacity-50"
              >
                <Play size={16} className="mr-2" /> Run Query
              </button>
              <button
                onClick={saveQuery}
                disabled={!queryInput.trim()}
                className="flex items-center px-4 py-2 bg-secondary text-white rounded hover:bg-secondary/90 disabled:opacity-50"
              >
                <Save size={16} className="mr-2" /> Save Query
              </button>
            </div>
          </div>

          {/* Query Results */}
          {queryResult && (
            <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold">Query Results</h3>
                <button
                  onClick={() => navigator.clipboard.writeText(JSON.stringify(queryResult, null, 2))}
                  className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded"
                >
                  <Copy size={16} />
                </button>
              </div>
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                  <thead>
                    <tr>
                      {queryResult.columns.map(column => (
                        <th
                          key={column}
                          className="px-4 py-2 bg-gray-50 dark:bg-gray-700 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                        >
                          {column}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-gray-700">
                    {queryResult.rows.map((row, i) => (
                      <tr key={i}>
                        {queryResult.columns.map(column => (
                          <td
                            key={column}
                            className="px-4 py-2 whitespace-nowrap text-sm text-gray-600 dark:text-gray-300"
                          >
                            {row[column]}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export default QueryBuilder;