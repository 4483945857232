import { Home, BarChart2, Database, FileText, Terminal, Box, Settings, HelpCircle, ChevronLeft, ChevronRight } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

function SidebarNavigation({ isOpen, setIsOpen }) {
  const location = useLocation();
  
  const navigation = [
    { name: 'Dashboard', icon: Home, path: '/' },
    { name: 'Products', icon: Box, path: '/products' },
    { name: 'Reports', icon: BarChart2, path: '/reports' },
    { name: 'Data Sources', icon: Database, path: '/data-sources' },
    { name: 'Query Builder', icon: Terminal, path: '/query-builder' },
    { name: 'Settings', icon: Settings, path: '/settings' },
    { name: 'Support', icon: HelpCircle, path: '/support' },
  ];

  return (
    <aside 
      className={`flex-shrink-0 bg-gray-800 text-white ${isOpen ? 'w-64' : 'w-16'} 
        transition-all duration-200 ease-in-out`}
      role="navigation"
      aria-label="Main navigation"
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-4">
          {isOpen && (
            <span className="text-xl font-bold text-white" role="heading">
              Menu
            </span>
          )}
          <button 
            onClick={() => setIsOpen(prev => !prev)}
            className="p-2 rounded-lg text-gray-300
              hover:bg-gray-700 hover:text-white
              focus:outline-none focus:ring-2 focus:ring-white
              transition-colors"
            aria-label={isOpen ? 'Collapse sidebar' : 'Expand sidebar'}
            title={isOpen ? 'Collapse sidebar' : 'Expand sidebar'}
          >
            {isOpen ? (
              <ChevronLeft size={24} aria-hidden="true" />
            ) : (
              <ChevronRight size={24} aria-hidden="true" />
            )}
          </button>
        </div>

        <nav className="flex-1 py-4" role="menubar">
          {navigation.map(item => (
            <Link
              key={item.name}
              to={item.path}
              className={`flex items-center px-4 py-2 my-1 mx-2 rounded-lg
                transition-colors group relative
                ${location.pathname === item.path 
                  ? 'bg-gray-700 text-white' 
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'}
                focus:outline-none focus:ring-2 focus:ring-white`}
              role="menuitem"
              aria-current={location.pathname === item.path ? 'page' : undefined}
              title={!isOpen ? item.name : undefined}
            >
              <item.icon 
                size={20} 
                className={`${!isOpen ? 'mx-auto' : 'mr-3'}`}
                aria-hidden="true"
              />
              {isOpen && <span>{item.name}</span>}
              {!isOpen && (
                <span className="absolute left-full ml-2 px-2 py-1 bg-gray-900 text-white
                  rounded opacity-0 group-hover:opacity-100 transition-opacity
                  text-sm whitespace-nowrap">
                  {item.name}
                </span>
              )}
            </Link>
          ))}
        </nav>
      </div>
    </aside>
  );
}

export default SidebarNavigation;