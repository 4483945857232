export const categories = {
  CLOUD_UC: 'Cloud UC Integrations',
  UCAAS: 'UCaaS Integrations',
  PLATFORM: 'Platform Integrations',
  ON_PREMISE: 'On-Premise PBX Integrations'
};

export const products = [
  {
    id: 'expo-xt',
    name: 'Expo XT',
    category: [categories.CLOUD_UC, categories.UCAAS, categories.PLATFORM, categories.ON_PREMISE],
    description: 'Enterprise-grade analytics and reporting platform supporting cloud and on-premise UC solutions',
    price: '$299/month',
    rating: 4.8,
    logo: 'expoBlue48',
    features: [
      'Microsoft Teams Integration',
      'Webex Calling Support',
      'Zoom Analytics',
      'Cisco UCM Integration',
      'Azure OpenAI Integration',
      'Microsoft Fabric Support'
    ],
    integrations: ['teams', 'webex', 'cisco', 'openAI', 'microsoftFabric'],
    demoAvailable: true
  },
  {
    id: 'qcloud',
    name: 'QCloud',
    category: [categories.CLOUD_UC, categories.UCAAS, categories.ON_PREMISE],
    description: 'Cloud-based UC analytics solution with comprehensive integration support',
    price: '$199/month',
    rating: 4.7,
    logo: 'qCloud',
    features: [
      'Microsoft Teams Analytics',
      'Nice CXone Integration',
      'Mitel Support',
      'Power BI Integration',
      'Microsoft Fabric Analytics'
    ],
    integrations: ['teams', 'nice', 'powerBI', 'microsoftFabric'],
    demoAvailable: true
  },
  {
    id: 'ai-insights',
    name: 'AI Insights',
    category: [categories.CLOUD_UC, categories.UCAAS, categories.PLATFORM],
    description: 'AI-powered analytics platform with advanced machine learning capabilities',
    price: '$399/month',
    rating: 4.9,
    logo: 'aInsightsLogo',
    features: [
      'Azure Cosmos DB Integration',
      'Azure AI Search',
      'ChatGPT Integration',
      'Power BI Analytics',
      'Microsoft Fabric Support'
    ],
    integrations: ['openAI', 'powerBI', 'microsoftFabric', 'CosmosDB'],
    demoAvailable: true
  },
  // ... Add other products similarly
];

export const integrationIcons = {
  teams: 'teamsResize',
  webex: 'webexResize',
  cisco: 'ciscoResize',
  nice: 'niceResize',
  openAI: 'openAILogo',
  powerBI: 'powerBILogo',
  microsoftFabric: 'microsoftFabricLogo',
  CosmosDB: 'CosmosDBLogo'
};
