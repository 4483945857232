// src/pages/Reports.jsx

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Download, RefreshCw, Calendar, BarChart, LineChart, Edit, Users, CalendarClock } from 'lucide-react';
import PowerBIReport from '../components/PowerBIReport';
import ReportsComponent from '../components/reports';

const Reports = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [activeReport, setActiveReport] = useState('reports');
  const [dateRange, setDateRange] = useState('last7days');
  const [exportFormat, setExportFormat] = useState('pdf');
  const [error, setError] = useState(null);
  const [reportData, setReportData] = useState(null);

  const reports = [
    { id: 'reports', name: 'Reports', icon: BarChart },
    { id: 'dashboards', name: 'Dashboards', icon: LineChart },
    { id: 'users', name: 'Users', icon: Users },
    { id: 'assign', name: 'Assign', icon: Edit },
  ];

  const reportConfigs = {
    reports: {
      reportId: '32b115b9-95e5-43ac-81d7-e3bbad8aabfa',
      title: 'Expo XT Cisco CDR (AppSource)'
    },
    dashboards: {
      reportId: '',
      title: 'System Dashboard'
    }
  };

  const handleExport = async (format) => {
    setExportFormat(format);
    try {
      // Implementation for export functionality
      const response = await fetch(`/api/reports/export/${activeReport}?format=${format}&dateRange=${dateRange}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `report-${activeReport}-${dateRange}.${format}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      setError('Failed to export report');
      console.error(err);
    }
  };

  const renderReportContent = () => {
    if (activeReport === 'reports' || activeReport === 'dashboards') {
      return (
        <div className="w-full">
          <PowerBIReport
            reportId={reportConfigs[activeReport].reportId}
            title={reportConfigs[activeReport].title}
          />
        </div>
      );
    }

    // Render other report types...
    return (
      <div className="h-96 flex items-center justify-center text-gray-500">
        No content available for {activeReport}
      </div>
    );
  };

  return (
    <main className="p-6 space-y-8">
      {/* Breadcrumb Navigation */}
      <nav className="flex items-center space-x-2 text-sm text-gray-500">
        <Link to="/" className="hover:text-gray-700">Home</Link>
        <ChevronRight size={16} />
        <Link to="/reports" className="text-gray-900">Reports</Link>
      </nav>

      {/* Header Section */}
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Reports Dashboard</h1>
        
        {/* Toolbar */}
        <div className="flex items-center space-x-4">
          {/* Date Range Selector */}
          <div className="flex items-center space-x-2">
            <Calendar size={20} className="text-gray-500" />
            <select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              className="border rounded-md px-2 py-1"
            >
              <option value="last7days">Last 7 Days</option>
              <option value="last30days">Last 30 Days</option>
              <option value="lastmonth">Last Month</option>
              <option value="custom">Custom Range</option>
            </select>
          </div>

          {/* Export Button */}
          <div className="relative inline-block">
            <button
              onClick={() => handleExport(exportFormat)}
              className="flex items-center space-x-2 px-4 py-2 border rounded-md hover:bg-gray-50"
            >
              <Download size={20} />
              <span>Export</span>
            </button>
          </div>

          {/* Refresh Button */}
          <button
            onClick={() => window.location.reload()}
            className="p-2 hover:bg-gray-100 rounded-full"
            title="Refresh"
          >
            <RefreshCw size={20} />
          </button>
        </div>
      </div>

      {/* Rest of your existing grid layout */}
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Report Types Sidebar */}
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <h2 className="font-semibold mb-4">Report Types</h2>
          <div className="space-y-2">
            {reports.map((report) => (
              <button
                key={report.id}
                onClick={() => setActiveReport(report.id)}
                className={`w-full flex items-center p-2 rounded-lg transition-colors ${
                  activeReport === report.id
                    ? 'bg-primary text-white'
                    : 'hover:bg-gray-100 dark:hover:bg-gray-700'
                }`}
              >
                <report.icon size={20} className="mr-2" />
                {report.name}
              </button>
            ))}
          </div>
        </div>

        {/* Main Report Content */}
        <div className="lg:col-span-3 space-y-4">
          {/* Your existing toolbar */}
          
          {/* Report Visualization */}
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
            {renderReportContent()}
          </div>

          {/* Report Scheduler Section */}
          {activeReport === 'reports' && (
            <section className="mt-8 bg-white p-6 rounded-lg shadow">
              <ReportsComponent />
            </section>
          )}
        </div>
      </div>
    </main>
  );
};

export default Reports;