// Integrations
import aIStudioLogo from '../assets/integrations/aIStudioLogo.svg';
import microsoftFabricLogo from '../assets/integrations/microsoftFabricLogo.svg';
import openAIAzureServiceLogo from '../assets/integrations/openAIAzureServiceLogo.svg';
import openAILogo from '../assets/integrations/openAILogo.svg';
import powerBILogo from '../assets/integrations/powerBILogo.svg';

// Logos
import MetropolisTophatLogo from '../assets/logos/MetropolisTophatLogo.svg';
import aInsightsAnalyticsLogo from '../assets/logos/aInsightsAnalyticsLogo.svg';
import aInsightsLogo from '../assets/logos/aInsightsLogo.svg';
import banner from '../assets/logos/banner.svg';
import entraClean from '../assets/logos/entraClean.svg';
import logo from '../assets/logos/logo.svg';
import qCloud from '../assets/logos/qCloud.svg';

// Partners
import CosmosDBLogo from '../assets/partners/CosmosDBLogo.svg';
import avayaResize from '../assets/partners/avayaResize.svg';
import ciscoResize from '../assets/partners/ciscoResize.svg';
import necResize from '../assets/partners/necResize.svg';
import niceResize from '../assets/partners/niceResize.svg';
import partnerOpenAILogo from '../assets/partners/openAILogo.svg';
import samsungResize from '../assets/partners/samsungResize.svg';
import teamsResize from '../assets/partners/teamsResize.svg';
import webexResize from '../assets/partners/webexResize.svg';

export const integrationLogos = {
  aIStudio: aIStudioLogo,
  microsoftFabric: microsoftFabricLogo,
  openAIAzureService: openAIAzureServiceLogo,
  openAI: openAILogo,
  powerBI: powerBILogo,
};

export const brandLogos = {
  MetropolisTophat: MetropolisTophatLogo,
  aInsightsAnalytics: aInsightsAnalyticsLogo,
  aInsights: aInsightsLogo,
  banner,
  entraClean,
  logo,
  qCloud,
};

export const partnerLogos = {
  CosmosDB: CosmosDBLogo,
  avaya: avayaResize,
  cisco: ciscoResize,
  nec: necResize,
  nice: niceResize,
  openAI: partnerOpenAILogo,
  samsung: samsungResize,
  teams: teamsResize,
  webex: webexResize,
};
