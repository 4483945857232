import { Info } from 'lucide-react';
import { brandLogos, integrationLogos } from '../utils/assetMap';

function QuickInsightsPanel({ data }) {
  return (
    <div className="grid grid-cols-2 gap-4">
      {Object.entries(data).map(([key, item]) => (
        <div key={key} className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow">
          <div className="flex items-center justify-between mb-2">
            <div className="p-2 bg-primary/10 rounded">
              <item.icon className="text-primary" size={24} />
            </div>
            <span className={`text-sm font-medium ${
              item.trend === 'up' ? 'text-green-500' : 'text-red-500'
            }`}>
              {item.change}
            </span>
          </div>
          <h3 className="text-sm text-gray-600 dark:text-gray-400">{item.label}</h3>
          <p className="text-2xl font-bold text-gray-900 dark:text-gray-100">{item.value}</p>
        </div>
      ))}
    </div>
  );
}

export default QuickInsightsPanel;