import { useState } from 'react';
import ProductOverview from './ProductOverview';
import ProductComparison from './ProductComparison';
import ProductGallery from './ProductGallery';
import ShoppingCart from './ShoppingCart';
import AutomatedDeploymentProcess from './AutomatedDeploymentProcess';
import { products } from '../utils/productData'; // Import products data

function ProductsSection() {
  const [isCartOpen, setIsCartOpen] = useState(false);

  return (
    <div className="mb-6">
      <ProductOverview />
      <ProductComparison products={products} /> {/* Pass products prop */}
      <ProductGallery />
      {isCartOpen && (
        <ShoppingCart 
          cartItems={[]} 
          onClose={() => setIsCartOpen(false)} 
        />
      )}
      <AutomatedDeploymentProcess />
    </div>
  );
}

export default ProductsSection;