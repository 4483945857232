import { useState } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

function RealTimeMetrics() {
  const [timeRange, setTimeRange] = useState('7d');
  const [selectedAgent, setSelectedAgent] = useState('all');

  const callData = [
    { time: 'Mon', calls: 150, duration: 450, handled: 142 },
    { time: 'Tue', calls: 230, duration: 680, handled: 225 },
    { time: 'Wed', calls: 180, duration: 550, handled: 178 },
    { time: 'Thu', calls: 290, duration: 720, handled: 285 },
    { time: 'Fri', calls: 200, duration: 600, handled: 195 },
    { time: 'Sat', calls: 120, duration: 320, handled: 118 },
    { time: 'Sun', calls: 90, duration: 280, handled: 88 }
  ];

  const agentPerformance = [
    { agent: 'Team A', handled: 245, abandoned: 12 },
    { agent: 'Team B', handled: 188, abandoned: 8 },
    { agent: 'Team C', handled: 276, abandoned: 15 },
    { agent: 'Team D', handled: 198, abandoned: 10 }
  ];

  return (
    <section className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-200">
          Real-Time Metrics
        </h2>
        <div className="flex space-x-4">
          <select
            value={timeRange}
            onChange={(e) => setTimeRange(e.target.value)}
            className="px-3 py-1 border rounded dark:bg-gray-700 dark:border-gray-600"
          >
            <option value="24h">Last 24 Hours</option>
            <option value="7d">Last 7 Days</option>
            <option value="30d">Last 30 Days</option>
          </select>
          <select
            value={selectedAgent}
            onChange={(e) => setSelectedAgent(e.target.value)}
            className="px-3 py-1 border rounded dark:bg-gray-700 dark:border-gray-600"
          >
            <option value="all">All Agents</option>
            <option value="team-a">Team A</option>
            <option value="team-b">Team B</option>
            <option value="team-c">Team C</option>
          </select>
        </div>
      </div>

      <div className="mb-8 h-72">
        <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400 mb-4">
          Call Volume Trend
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={callData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="calls" stroke="#3B82F6" strokeWidth={2} dot={false} />
            <Line type="monotone" dataKey="handled" stroke="#10B981" strokeWidth={2} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="h-72">
        <h3 className="text-sm font-medium text-gray-600 dark:text-gray-400 mb-4">
          Agent Performance
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={agentPerformance}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="agent" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="handled" fill="#3B82F6" />
            <Bar dataKey="abandoned" fill="#EF4444" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
}

export default RealTimeMetrics;