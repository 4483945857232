import React from 'react';
import { HelpCircle, Phone, Mail, Globe, Search, ShoppingCart, Bell, Sun, Moon } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { brandLogos } from '../utils/assetMap';
import { Home, BarChart2, Database, FileText, Terminal, Box, Settings, ChevronLeft, ChevronRight } from 'lucide-react';

function TopNavigationBar({ setSidebarOpen, toggleTheme, theme }) {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <header className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 shadow" role="banner">
      <div className="flex items-center">
        <button 
          onClick={() => setSidebarOpen(prev => !prev)} 
          className="mr-4 focus:outline-none focus:ring-2 focus:ring-primary rounded p-1 hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors"
          aria-label="Toggle sidebar navigation"
        >
          <svg width="24" height="24" fill="none">
            <path d="M4 6h16M4 12h16M4 18h16" stroke={theme === 'dark' ? '#fff' : '#000'} strokeWidth="2" />
          </svg>
        </button>

        <Link 
          to="/" 
          className="focus:outline-none focus:ring-2 focus:ring-primary rounded"
          aria-label="Go to homepage"
        >
          <img src={brandLogos.logo} alt="MetroLink" className="h-8 w-auto" />
        </Link>

        <nav className="ml-8 space-x-4 hidden md:block" role="navigation">
          {[
            { to: "/", label: "Dashboard" },
            { to: "/products", label: "Products" },
            { to: "/reports", label: "Reports" },
            { to: "/data-sources", label: "Data Sources" },
            { to: "/query-builder", label: "Query Builder" }
          ].map(link => (
            <Link
              key={link.to}
              to={link.to}
              className="text-gray-700 dark:text-gray-300 hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary rounded px-2 py-1 transition-colors"
            >
              {link.label}
            </Link>
          ))}
        </nav>
      </div>

      <div className="flex items-center">
        <div className="relative mr-4">
          <input
            type="text"
            placeholder="Search..."
            className="pl-10 pr-4 py-2 border rounded dark:bg-gray-700 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-primary"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            aria-label="Search"
          />
          <Search className="absolute left-2 top-2 text-gray-500 dark:text-gray-300" size={20} aria-hidden="true" />
        </div>

        <button 
          className="relative mr-4 p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary transition-colors"
          aria-label={`Shopping cart with 3 items`}
          title="View shopping cart"
        >
          <ShoppingCart size={24} className="text-gray-700 dark:text-gray-300" />
          <span 
            className="absolute top-0 right-0 inline-flex items-center justify-center h-4 w-4 text-xs text-white bg-red-500 rounded-full"
            aria-hidden="true"
          >
            3
          </span>
        </button>

        <button 
          className="relative mr-4 p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary transition-colors"
          aria-label={`5 unread notifications`}
          title="View notifications"
        >
          <Bell size={24} className="text-gray-700 dark:text-gray-300" />
          <span 
            className="absolute top-0 right-0 inline-flex items-center justify-center h-4 w-4 text-xs text-white bg-red-500 rounded-full"
            aria-hidden="true"
          >
            5
          </span>
        </button>

        <button 
          onClick={toggleTheme} 
          className="mr-4 p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary transition-colors"
          aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
          title={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
        >
          {theme === 'light' ? (
            <Moon size={24} className="text-gray-700" />
          ) : (
            <Sun size={24} className="text-yellow-500" />
          )}
        </button>

        <button
          className="h-8 w-8 rounded-full focus:outline-none focus:ring-2 focus:ring-primary overflow-hidden"
          aria-label="Open user menu"
          title="User menu"
        >
          <img src="/user-avatar.png" alt="User avatar" className="h-full w-full object-cover" />
        </button>
      </div>
    </header>
  );
}

function SidebarNavigation({ isOpen, setIsOpen }) {
  const location = useLocation();
  
  const navigation = [
    { name: 'Dashboard', icon: Home, path: '/' },
    { name: 'Products', icon: Box, path: '/products' },
    { name: 'Reports', icon: BarChart2, path: '/reports' },
    { name: 'Data Sources', icon: Database, path: '/data-sources' },
    { name: 'Query Builder', icon: Terminal, path: '/query-builder' },
    { name: 'Settings', icon: Settings, path: '/settings' },
    { name: 'Support', icon: HelpCircle, path: '/support' },
  ];

  return (
    <aside 
      className={`flex-shrink-0 bg-gray-800 text-white ${isOpen ? 'w-64' : 'w-16'} transition-width duration-200`}
      role="navigation"
      aria-label="Main navigation"
    >
      <div className="flex flex-col h-full">
        <div className="flex items-center justify-between p-4">
          {isOpen && <span className="text-xl font-bold">Menu</span>}
          <button 
            onClick={() => setIsOpen(prev => !prev)} 
            className="p-2 rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-white transition-colors"
            aria-label={isOpen ? 'Collapse sidebar' : 'Expand sidebar'}
          >
            {isOpen ? <ChevronLeft size={24} /> : <ChevronRight size={24} />}
          </button>
        </div>

        <nav className="flex-1" role="menubar">
          {navigation.map(item => (
            <Link
              key={item.name}
              to={item.path}
              className={`flex items-center px-4 py-2 my-1 mx-2 rounded-lg transition-colors
                ${location.pathname === item.path 
                  ? 'bg-gray-700 text-white' 
                  : 'text-gray-300 hover:bg-gray-700 hover:text-white'}
                focus:outline-none focus:ring-2 focus:ring-white`}
              role="menuitem"
              aria-current={location.pathname === item.path ? 'page' : undefined}
              title={!isOpen ? item.name : undefined}
            >
              <item.icon size={20} aria-hidden="true" />
              {isOpen && <span className="ml-4">{item.name}</span>}
            </Link>
          ))}
        </nav>
      </div>
    </aside>
  );
}

function Support() {
  const supportCards = [
    {
      icon: HelpCircle,
      title: "Help Center",
      description: "Browse our knowledge base for answers to common questions and issues.",
      action: "View Documentation",
      onClick: () => window.open('/documentation', '_blank'),
    },
    {
      icon: Phone,
      title: "Contact Support",
      description: "Reach out to our support team for direct assistance with your issues.",
      action: "Contact Us",
      onClick: () => window.location.href = 'tel:+1-800-support',
    },
    {
      icon: Mail,
      title: "Email Support",
      description: "Send us an email and we'll get back to you within 24 hours.",
      action: "support@example.com",
      href: "mailto:support@example.com",
    }
  ];

  return (
    <main className="p-6" role="main">
      <h1 className="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-200">
        Support Center
      </h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {supportCards.map((card, index) => (
          <div 
            key={index}
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md 
              hover:shadow-lg focus-within:ring-2 focus-within:ring-primary
              transform transition-all duration-200 hover:-translate-y-1"
            role="region"
            aria-labelledby={`support-title-${index}`}
          >
            <card.icon 
              className="h-8 w-8 text-primary mb-4" 
              aria-hidden="true" 
            />
            <h2 
              id={`support-title-${index}`}
              className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200"
            >
              {card.title}
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              {card.description}
            </p>
            {card.href ? (
              <a 
                href={card.href}
                className="inline-flex items-center text-primary hover:text-primary-dark
                  focus:outline-none focus:underline font-medium transition-colors"
                aria-label={`Send email to ${card.action}`}
              >
                {card.action} <span aria-hidden="true">→</span>
              </a>
            ) : (
              <button
                onClick={card.onClick}
                className="inline-flex items-center text-primary hover:text-primary-dark
                  focus:outline-none focus:ring-2 focus:ring-primary rounded px-2 py-1
                  font-medium transition-colors"
                aria-label={card.action}
              >
                {card.action} <span aria-hidden="true">→</span>
              </button>
            )}
          </div>
        ))}
      </div>

      <div className="mt-8 bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
        <Globe 
          className="h-8 w-8 text-primary mb-4" 
          aria-hidden="true" 
        />
        <h2 className="text-xl font-semibold mb-3 text-gray-800 dark:text-gray-200">
          System Status
        </h2>
        <div className="flex items-center space-x-2">
          <div 
            className="h-3 w-3 bg-green-500 rounded-full"
            role="status"
            aria-hidden="true"
          />
          <span className="text-gray-600 dark:text-gray-400">
            All systems operational
          </span>
        </div>
      </div>
    </main>
  );
}

export default Support;
