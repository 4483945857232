import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, RefreshCw, BarChart2, Activity, Brain, Users } from 'lucide-react';
import QuickInsightsPanel from '../components/QuickInsightsPanel';
import RealTimeMetrics from '../components/RealTimeMetrics';
import PBXIntegrationFilters from '../components/PBXIntegrationFilters';
import DeploymentStatus from '../components/DeploymentStatus';

function Dashboard() {
  const [isLoading, setIsLoading] = useState(true);
  const [lastRefreshed, setLastRefreshed] = useState(new Date());
  const [error, setError] = useState(null);
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem('theme') || 'light';
  });
  const [sidebarOpen, setSidebarOpen] = useState(false); // Add this line

  const analyticsData = {
    insights: {
      value: '$123,456',
      change: '+12.5%',
      trend: 'up',
      label: 'Analytics Insights',
      icon: BarChart2,
    },
    predictions: {
      value: '76%',
      change: '+5.2%',
      trend: 'up',
      label: 'AI Predictions',
      icon: Brain,
    },
    realtimeUsers: {
      value: '1,234',
      change: '-2.1%',
      trend: 'down',
      label: 'Active Users',
      icon: Users,
    },
    performance: {
      value: '98.5%',
      change: '+0.5%',
      trend: 'up',
      label: 'System Performance',
      icon: Activity,
    }
  };

  const deploymentStatus = [
    {
      id: 1,
      name: 'Teams Integration',
      status: 'Deploying',
      progress: 70,
      startTime: '2024-01-20T09:00:00',
      eta: '2024-01-20T10:30:00'
    },
    {
      id: 2,
      name: 'Webex Analytics',
      status: 'Active',
      progress: 100,
      startTime: '2024-01-19T14:00:00',
      completedTime: '2024-01-19T15:45:00'
    },
    {
      id: 3,
      name: 'Cisco UCM Update',
      status: 'Idle',
      progress: 0,
      scheduledTime: '2024-01-21T08:00:00'
    }
  ];

  useEffect(() => {
    loadDashboardData();
  }, []);

  useEffect(() => {
    document.documentElement.classList.add(theme);
  }, [theme]);

  const loadDashboardData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      // Simulate API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      setLastRefreshed(new Date());
    } catch (err) {
      setError('Failed to load dashboard data');
    } finally {
      setIsLoading(false);
    }
  };

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    document.documentElement.classList.replace(theme, newTheme);
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <main className="p-4 space-y-4">
      {/* Breadcrumb Navigation */}
      <nav className="flex items-center text-sm text-gray-500 dark:text-gray-400">
        <Link to="/" className="hover:text-primary">Home</Link>
        <ChevronRight size={16} className="mx-2" />
        <span className="text-gray-900 dark:text-gray-200">Dashboard</span>
      </nav>

      {/* Header Section */}
      <header className="mb-6">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">Dashboard</h1>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Overview of your analytics and system performance
            </p>
          </div>
          <div className="flex items-center mt-4 md:mt-0 space-x-4">
            <span className="text-sm text-gray-500 dark:text-gray-400">
              Last updated: {lastRefreshed.toLocaleTimeString()}
            </span>
            <button 
              onClick={loadDashboardData}
              disabled={isLoading}
              className="flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors disabled:opacity-50 focus:outline-none focus:ring-2 focus:ring-primary"
              aria-label="Refresh dashboard data"
            >
              <RefreshCw size={20} className={`mr-2 ${isLoading ? 'animate-spin' : ''}`} />
              Refresh
            </button>
          </div>
        </div>
      </header>

      {/* Error Message */}
      {error && (
        <div className="bg-red-50 dark:bg-red-900/50 border-l-4 border-red-500 p-4 mb-4">
          <p className="text-red-700 dark:text-red-200">{error}</p>
        </div>
      )}

      {/* Dashboard Content */}
      <div className="grid gap-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
          </div>
        ) : (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <QuickInsightsPanel data={analyticsData} />
              <RealTimeMetrics />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <PBXIntegrationFilters />
              <DeploymentStatus deployments={deploymentStatus} />
            </div>
          </>
        )}
      </div>
    </main>
  );
}

export default Dashboard;