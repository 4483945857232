import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Database, Plus, RefreshCw, Check, X, AlertTriangle } from 'lucide-react';

function DataSources() {
  const [isLoading, setIsLoading] = useState(true);
  const [dataSources, setDataSources] = useState([
    { 
      id: 1, 
      name: 'Zoom Analytics', 
      type: 'PBX Integration',
      status: 'connected',
      lastSync: '2 minutes ago',
      dataPoints: 15420
    },
    { 
      id: 2, 
      name: 'Microsoft Teams Data', 
      type: 'PBX Integration',
      status: 'error',
      lastSync: '1 hour ago',
      dataPoints: 8750
    },
    { 
      id: 3, 
      name: 'Webex Statistics', 
      type: 'PBX Integration',
      status: 'pending',
      lastSync: 'Never',
      dataPoints: 0
    }
  ]);

  useEffect(() => {
    // Simulate loading data sources
    setTimeout(() => setIsLoading(false), 800);
  }, []);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'connected': return <Check className="text-green-500" size={20} />;
      case 'error': return <X className="text-red-500" size={20} />;
      case 'pending': return <AlertTriangle className="text-yellow-500" size={20} />;
      default: return null;
    }
  };

  return (
    <main className="p-4 space-y-4">
      {/* Breadcrumb Navigation */}
      <nav className="flex items-center text-sm text-gray-500 dark:text-gray-400">
        <Link to="/" className="hover:text-primary">Home</Link>
        <ChevronRight size={16} className="mx-2" />
        <span className="text-gray-900 dark:text-gray-200">Data Sources</span>
      </nav>

      {/* Header Section */}
      <header className="mb-6">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900 dark:text-gray-100">Data Sources</h1>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Manage and monitor your connected data sources and integrations
            </p>
          </div>
          <button className="flex items-center px-4 py-2 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors">
            <Plus size={20} className="mr-2" />
            Add New Source
          </button>
        </div>
      </header>

      {/* Main Content */}
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      ) : (
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow">
          {/* Data Sources List */}
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {dataSources.map(source => (
              <div key={source.id} className="p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Database className="text-primary mr-3" size={24} />
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100">
                        {source.name}
                      </h3>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {source.type}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <div className="text-right">
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        Last synced: {source.lastSync}
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {source.dataPoints.toLocaleString()} data points
                      </p>
                    </div>
                    <div className="flex items-center space-x-2">
                      {getStatusIcon(source.status)}
                      <button 
                        className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full"
                        title="Refresh connection"
                      >
                        <RefreshCw size={20} className="text-gray-500 dark:text-gray-400" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </main>
  );
}

export default DataSources;