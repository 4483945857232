import { useState, useEffect, useRef } from 'react';

function ShoppingCart({ cartItems = [], onClose }) {
  const [items, setItems] = useState(cartItems);
  const modalRef = useRef(null);

  const handleRemoveItem = (itemId) => {
    setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
    // Update global cart state if applicable
  };

  const totalPrice = items.reduce((total, item) => total + item.price, 0);

  // Handle click outside to close
  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  // Prevent scrolling when modal is open
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div 
        ref={modalRef}
        className="bg-white dark:bg-gray-800 p-6 rounded shadow-lg w-full max-w-md relative"
        role="dialog"
        aria-modal="true"
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">Shopping Cart</h2>
        {items.length > 0 ? (
          <div>
            {items.map((item) => (
              <div key={item.id} className="flex justify-between items-center mb-4">
                <span>{item.name}</span>
                <div className="flex items-center space-x-2">
                  <span>${item.price.toFixed(2)}</span>
                  <button
                    onClick={() => handleRemoveItem(item.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <div className="flex justify-between items-center font-semibold">
              <span>Total:</span>
              <span>${totalPrice.toFixed(2)}</span>
            </div>
            <button className="mt-4 w-full px-4 py-2 bg-primary text-white rounded">
              Proceed to Checkout
            </button>
          </div>
        ) : (
          <p className="text-gray-600 dark:text-gray-400 mb-4">Your cart is empty.</p>
        )}
        <button
          onClick={onClose}
          className="w-full px-4 py-2 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-200 rounded hover:bg-gray-300 dark:hover:bg-gray-600 transition-colors"
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default ShoppingCart;