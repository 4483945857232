import { Search, ShoppingCart as CartIcon, Bell, Sun, Moon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { brandLogos } from '../utils/assetMap';
import ShoppingCart from './ShoppingCart';

function TopNavigationBar({ setSidebarOpen, toggleTheme, theme }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const cartItemCount = 3; // Replace with actual cart item count
  const notificationCount = 5; // Replace with actual notification count

  const handleLogout = () => {
    // Implement logout functionality
  };

  return (
    <header className="flex items-center justify-between p-4 bg-white dark:bg-gray-800 shadow" role="banner">
      <div className="flex items-center">
        <button 
          onClick={() => setSidebarOpen(prev => !prev)} 
          className="mr-4 p-2 rounded hover:bg-gray-100 dark:hover:bg-gray-700 
            focus:outline-none focus:ring-2 focus:ring-primary transition-colors"
          aria-label="Toggle navigation menu"
        >
          <svg width="24" height="24" fill="none">
            <path 
              d="M4 6h16M4 12h16M4 18h16" 
              stroke={theme === 'dark' ? '#fff' : '#000'} 
              strokeWidth="2" 
            />
          </svg>
        </button>

        <Link 
          to="/" 
          className="focus:outline-none focus:ring-2 focus:ring-primary rounded 
            hover:opacity-80 transition-opacity"
          aria-label="Go to homepage"
        >
          <img src={brandLogos.logo} alt="MetroLink" className="h-8 w-auto" />
        </Link>

        <nav className="ml-8 space-x-4 hidden md:block" role="navigation">
          {[
            { to: "/", label: "Dashboard" },
            { to: "/products", label: "Products" },
            { to: "/reports", label: "Reports" },
            { to: "/data-sources", label: "Data Sources" },
            { to: "/query-builder", label: "Query Builder" }
          ].map(link => (
            <Link
              key={link.to}
              to={link.to}
              className="text-gray-700 dark:text-gray-300 px-3 py-2 rounded-lg
                hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-primary
                focus:outline-none focus:ring-2 focus:ring-primary transition-colors"
              aria-label={`Navigate to ${link.label}`}
            >
              {link.label}
            </Link>
          ))}
        </nav>
      </div>

      <div className="flex items-center space-x-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search..."
            className="pl-10 pr-4 py-2 border rounded-lg
              dark:bg-gray-700 dark:text-gray-300 
              focus:outline-none focus:ring-2 focus:ring-primary
              hover:border-gray-400 dark:hover:border-gray-500 transition-colors"
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            aria-label="Search"
          />
          <Search 
            className="absolute left-3 top-1/2 transform -translate-y-1/2 
              text-gray-500 dark:text-gray-400 pointer-events-none" 
            size={18} 
            aria-hidden="true" 
          />
        </div>

        <button 
          className="relative p-2 rounded-lg
            hover:bg-gray-100 dark:hover:bg-gray-700
            focus:outline-none focus:ring-2 focus:ring-primary transition-colors"
          aria-label={`Shopping cart with ${cartItemCount} items`}
          title={`View shopping cart (${cartItemCount} items)`}
          onClick={() => setIsCartOpen(!isCartOpen)}
        >
          <CartIcon className="text-gray-700 dark:text-gray-300" size={22} />
          <span 
            className="absolute -top-1 -right-1 h-5 w-5 text-xs flex items-center justify-center
              text-white bg-primary rounded-full"
            aria-hidden="true"
          >
            {cartItemCount}
          </span>
        </button>

        <button 
          className="relative p-2 rounded-lg
            hover:bg-gray-100 dark:hover:bg-gray-700
            focus:outline-none focus:ring-2 focus:ring-primary transition-colors"
          aria-label={`${notificationCount} unread notifications`}
          title={`${notificationCount} Unread Notifications`}
          onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
        >
          <Bell className="text-gray-700 dark:text-gray-300" size={22} />
          <span 
            className="absolute -top-1 -right-1 h-5 w-5 text-xs flex items-center justify-center
              text-white bg-primary rounded-full"
            aria-hidden="true"
          >
            {notificationCount}
          </span>
        </button>

        <button
          onClick={toggleTheme}
          aria-label={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
          className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-primary transition-colors"
          title={`Switch to ${theme === 'light' ? 'dark' : 'light'} mode`}
        >
          {theme === 'light' ? (
            <Moon className="text-gray-700" size={22} />
          ) : (
            <Sun className="text-yellow-500" size={22} />
          )}
        </button>

        <button
          className="h-8 w-8 rounded-full overflow-hidden
            hover:ring-2 hover:ring-gray-300 dark:hover:ring-gray-600
            focus:outline-none focus:ring-2 focus:ring-primary transition-all"
          aria-label="Open user menu"
          title="User menu"
          onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
        >
          <img 
            src="/user-avatar.png" 
            alt="User avatar" 
            className="h-full w-full object-cover"
          />
        </button>
      </div>

      {/* Shopping Cart Modal */}
      {isCartOpen && (
        <ShoppingCart 
          cartItems={[]} // Pass your cart items here
          onClose={() => setIsCartOpen(false)}
        />
      )}

      {/* Notifications Dropdown */}
      {isNotificationsOpen && (
        <div className="dropdown" role="menu" aria-label="Notifications">
          {/* Render notifications list */}
        </div>
      )}

      {/* User Menu Dropdown */}
      {isUserMenuOpen && (
        <div className="dropdown" role="menu" aria-label="User menu">
          <ul>
            <li>
              <Link to="/profile" onClick={() => setIsUserMenuOpen(false)}>
                Profile
              </Link>
            </li>
            <li>
              <Link to="/settings" onClick={() => setIsUserMenuOpen(false)}>
                Settings
              </Link>
            </li>
            <li>
              <button onClick={handleLogout}>Logout</button>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
}

export default TopNavigationBar;