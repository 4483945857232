import React from 'react';

const PowerBIReport = ({ reportId, title }) => (
  <iframe
    title={title}
    width="100%"
    height="600"
    src={`https://app.powerbi.com/reportEmbed?reportId=${reportId}&autoAuth=true&embeddedDemo=true`}
    frameBorder="0"
    allowFullScreen={true}
  />
);

export default PowerBIReport;
