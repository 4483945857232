import React from 'react';

// components/SchedulerWorkflow.jsx
export const SchedulerWorkflow = ({ currentStep }) => {
    const steps = [
      { id: 1, name: 'Select Report' },
      { id: 2, name: 'Configure Schedule' },
      { id: 3, name: 'Set Notifications' },
      { id: 4, name: 'Review & Save' }
    ];
  
    return (
      <div className="w-full py-4" role="navigation" aria-label="Schedule creation steps">
        <div className="flex items-center justify-between">
          {steps.map((step, idx) => (
            <React.Fragment key={step.id}>
              <div className="flex flex-col items-center">
                <div 
                  className={`
                    w-8 h-8 rounded-full flex items-center justify-center
                    ${currentStep >= step.id ? 'bg-blue-600 text-white' : 'bg-gray-200'}
                    transition-colors duration-300
                  `}
                  aria-current={currentStep === step.id ? 'step' : undefined}
                >
                  {step.id}
                </div>
                <span className="text-sm mt-2">{step.name}</span>
              </div>
              {idx < steps.length - 1 && (
                <div 
                  className={`
                    flex-1 h-1 mx-4
                    ${currentStep > step.id ? 'bg-blue-600' : 'bg-gray-200'}
                    transition-colors duration-300
                  `}
                  aria-hidden="true"
                />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  };