import { useState } from 'react';

function AutomatedDeploymentProcess() {
  const [step, setStep] = useState(1);
  const totalSteps = 4;

  const nextStep = () => {
    if (step < totalSteps) setStep(prev => prev + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(prev => prev - 1);
  };

  const completeDeployment = () => {
    setStep(totalSteps + 1);
  };

  return (
    <section className="mb-6">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Automated Deployment Process</h2>
      <div className="bg-white dark:bg-gray-800 p-6 rounded shadow">
        <div className="flex items-center mb-4">
          {[1,2,3,4].map(s => (
            <div key={s} className="flex-1">
              <div className={`h-2 rounded ${step >= s ? 'bg-primary' : 'bg-gray-300 dark:bg-gray-600'}`}></div>
              <span className="text-xs text-center block">Step {s}</span>
            </div>
          ))}
        </div>
        <div className="mb-4">
          {step === 1 && <p className="text-gray-700 dark:text-gray-300">Product Confirmation</p>}
          {step === 2 && <p className="text-gray-700 dark:text-gray-300">Deployment Settings</p>}
          {step === 3 && <p className="text-gray-700 dark:text-gray-300">Terms Acceptance</p>}
          {step === 4 && <p className="text-gray-700 dark:text-gray-300">Deployment Complete</p>}
        </div>
        <div className="flex justify-between">
          <button onClick={prevStep} disabled={step === 1} className={`p-2 rounded ${step === 1 ? 'bg-gray-300 dark:bg-gray-600 text-gray-500' : 'bg-secondary text-white'}`}>Back</button>
          {step < totalSteps && <button onClick={nextStep} className="p-2 bg-primary text-white rounded">Next</button>}
          {step === totalSteps && <button onClick={completeDeployment} className="p-2 bg-green-500 text-white rounded">Finish</button>}
        </div>
      </div>
    </section>
  );
}

export default AutomatedDeploymentProcess;