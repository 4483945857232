import { useState } from 'react';
import { ShoppingCart, Eye, Star } from 'lucide-react';

const sampleProducts = [
  {
    id: 1,
    name: 'Analytics Tool A',
    category: 'Analytics Tools',
    description: 'Advanced analytics tool for data visualization.',
    price: '$49.99',
    rating: 4.5,
  },
  {
    id: 2,
    name: 'Communication Platform B',
    category: 'Communication Platforms',
    description: 'Seamless communication platform for teams.',
    price: '$29.99',
    rating: 4.0,
  },
  {
    id: 3,
    name: 'Productivity App C',
    category: 'Productivity Apps',
    description: 'Boost your productivity with this app.',
    price: '$19.99',
    rating: 4.2,
  },
];

function ProductOverview() {
  const [cartItems, setCartItems] = useState([]);

  const addToCart = (product) => {
    setCartItems(prev => [...prev, product]);
  };

  return (
    <section className="mb-6">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Product Overview</h2>
      <div className="mb-4">
        <h3 className="text-lg font-bold text-gray-800 dark:text-gray-200">Trending Products</h3>
        <div className="flex space-x-4 overflow-x-auto mt-2">
          {sampleProducts.map(product => (
            <div key={product.id} className="min-w-[200px] bg-white dark:bg-gray-700 p-4 rounded shadow">
              <h4 className="font-semibold text-gray-800 dark:text-gray-200">{product.name}</h4>
              <p className="text-sm text-gray-500 dark:text-gray-400">{product.category}</p>
              <p className="mt-2 text-gray-600 dark:text-gray-300">{product.description}</p>
              <div className="flex items-center mt-2">
                <Star size={16} className="text-yellow-400" />
                <span className="ml-1">{product.rating}</span>
              </div>
              <p className="mt-2 text-lg font-bold text-gray-800 dark:text-gray-200">{product.price}</p>
              <div className="flex space-x-2 mt-4">
                <button onClick={() => addToCart(product)} className="flex-1 p-2 bg-primary text-white rounded flex items-center justify-center">
                  <ShoppingCart size={16} className="mr-2" /> Add to Cart
                </button>
                <button className="flex-1 p-2 bg-gray-200 dark:bg-gray-600 rounded flex items-center justify-center">
                  <Eye size={16} className="mr-2" /> Quick View
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        <h3 className="text-lg font-bold text-gray-800 dark:text-gray-200">Product Categories</h3>
        <div className="flex space-x-4 mt-2">
          <button className="p-2 bg-secondary text-white rounded">Analytics Tools</button>
          <button className="p-2 bg-secondary text-white rounded">Communication Platforms</button>
          <button className="p-2 bg-secondary text-white rounded">Productivity Apps</button>
        </div>
      </div>
    </section>
  );
}

export default ProductOverview;