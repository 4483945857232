import { useState, useMemo } from 'react';
import { ShoppingCart, Eye, Star, PlayCircle } from 'lucide-react';
import { brandLogos, partnerLogos, integrationLogos } from '../utils/assetMap';
import { products, categories, integrationIcons } from '../utils/productData';

// Add or update the mapping for integration labels
const integrationLabels = {
  teams: 'Teams Integration',
  webex: 'Webex Integration',
  cisco: 'Cisco Integration',
  nice: 'Nice Integration',
  openAI: 'OpenAI Integration',
  powerBI: 'Power BI Integration',
  microsoftFabric: 'Microsoft Fabric Integration',
  CosmosDB: 'Cosmos DB Integration'
};

function ProductGallery() {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [selectedIntegrations, setSelectedIntegrations] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showDemo, setShowDemo] = useState(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isDemoOpen, setIsDemoOpen] = useState(false);

  const displayProducts = useMemo(() => {
    return products.filter(product => {
      const matchesCategory = selectedCategory === 'all' || 
        product.category.includes(selectedCategory);
      const matchesIntegrations = selectedIntegrations.length === 0 || 
        selectedIntegrations.every(integration => 
          product.integrations.includes(integration)
        );
      const matchesSearch = product.name.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase()
        .includes(searchTerm.toLowerCase());

      return matchesCategory && matchesIntegrations && matchesSearch;
    });
  }, [selectedCategory, selectedIntegrations, searchTerm]);

  const addToCart = (product) => {
    setCartItems(prev => [...prev, product]);
    // Show confirmation toast or modal
  };

  const viewProductDetails = (product) => {
    setSelectedProduct(product);
    setIsDetailsOpen(true);
  };

  const runDemoReport = (product) => {
    setSelectedProduct(product);
    setIsDemoOpen(true);
  };

  const handleViewDetails = (product) => {
    setSelectedProduct(product);
    setIsDetailsOpen(true);
  };

  const handleRunDemo = (product) => {
    setSelectedProduct(product);
    setIsDemoOpen(true);
  };

  return (
    <section className="mb-6">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">
        Product Gallery
      </h2>
      
      {/* Filtering and Search */}
      <div className="mb-6 space-y-4">
        <div className="flex flex-wrap gap-4">
          <input
            type="text"
            placeholder="Search products..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="p-2 border rounded dark:bg-gray-700 dark:text-gray-300"
          />
          <select
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="p-2 border rounded dark:bg-gray-700 dark:text-gray-300"
          >
            <option value="all">All Categories</option>
            {Object.values(categories).map(category => (
              <option key={category} value={category}>{category}</option>
            ))}
          </select>
        </div>

        {/* Integration Filters */}
        <div className="flex flex-wrap gap-2">
          {Object.entries(integrationIcons).map(([key, icon]) => {
            // Get the correct logo source based on the integration type
            const logoSrc = integrationLogos[key] || partnerLogos[key];
            
            return (
              <button
                key={key}
                onClick={() => setSelectedIntegrations(prev => 
                  prev.includes(key) 
                    ? prev.filter(i => i !== key)
                    : [...prev, key]
                )}
                className={`flex items-center p-2 rounded ${
                  selectedIntegrations.includes(key)
                    ? 'bg-primary text-white'
                    : 'bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600'
                }`}
              >
                <div className="w-6 h-6 relative mr-2">
                  <img
                    src={logoSrc}
                    alt={`${key} icon`}
                    className="w-full h-full object-contain"
                  />
                </div>
                <span className="text-sm">{integrationLabels[key] || key}</span>
              </button>
            );
          })}
        </div>
      </div>

      {/* Products Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {displayProducts.map(product => (
          <div key={product.id} className="bg-white dark:bg-gray-700 p-4 rounded shadow hover:shadow-lg transition-shadow">
            {/* Product Header */}
            <div className="flex items-center mb-3">
              <img 
                src={brandLogos[product.logo] || brandLogos.logo} 
                alt={`${product.name} logo`}
                className="h-8 w-auto mr-2"
              />
              <h3 className="text-lg font-bold text-gray-800 dark:text-gray-200">{product.name}</h3>
            </div>

            {/* Product Details */}
            <p className="text-sm text-gray-500 dark:text-gray-400">{product.category}</p>
            <p className="mt-2 text-gray-600 dark:text-gray-300">{product.description}</p>
            
            {/* Features List */}
            <ul className="mt-2 space-y-1">
              {product.features?.map((feature, index) => (
                <li key={index} className="text-sm text-gray-600 dark:text-gray-400">
                  • {feature}
                </li>
              ))}
            </ul>

            {/* Rating */}
            <div className="flex items-center mt-2">
              <Star size={16} className="text-yellow-400" />
              <span className="ml-1">{product.rating}</span>
            </div>

            {/* Price */}
            <p className="mt-2 text-lg font-bold text-gray-800 dark:text-gray-200">{product.price}</p>

            {/* Action Buttons */}
            <div className="flex flex-col space-y-2 mt-4">
              <button 
                onClick={() => addToCart(product)} 
                className="w-full p-2 bg-primary text-white rounded flex items-center justify-center hover:bg-primary/90 transition-colors focus:outline-none focus:ring-2 focus:ring-primary"
                aria-label="Add to cart"
              >
                <ShoppingCart size={16} className="mr-2" /> Add to Cart
              </button>
              <div className="flex space-x-2">
                <button 
                  onClick={() => viewProductDetails(product)}
                  className="flex-1 p-2 bg-gray-200 dark:bg-gray-600 rounded flex items-center justify-center hover:bg-gray-300 dark:hover:bg-gray-500 transition-colors focus:outline-none focus:ring-2 focus:ring-primary"
                  aria-label="Quick view"
                >
                  <Eye size={16} className="mr-2" /> Quick View
                </button>
                {product.demoAvailable && (
                  <button 
                    onClick={() => runDemoReport(product)}
                    className="flex-1 p-2 bg-secondary text-white rounded flex items-center justify-center hover:bg-secondary/90 transition-colors focus:outline-none focus:ring-2 focus:ring-primary"
                    aria-label="Run demo"
                  >
                    <PlayCircle size={16} className="mr-2" /> Run Demo
                  </button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Product Details Modal */}
      {isDetailsOpen && selectedProduct && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
          <div className="bg-white dark:bg-gray-800 p-6 rounded shadow-lg">
            <h3 className="text-2xl font-bold mb-4">{selectedProduct.name}</h3>
            <p>{selectedProduct.description}</p>
            {/* Additional product details */}
            <button
              onClick={() => setIsDetailsOpen(false)}
              className="mt-4 px-4 py-2 bg-primary text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}

      {/* Demo Report Modal */}
      {isDemoOpen && selectedProduct && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
          <div className="bg-white dark:bg-gray-800 p-6 rounded shadow-lg">
            <h3 className="text-2xl font-bold mb-4">
              Demo Report for {selectedProduct.name}
            </h3>
            {/* Render demo report content */}
            <button
              onClick={() => setIsDemoOpen(false)}
              className="mt-4 px-4 py-2 bg-primary text-white rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

export default ProductGallery;